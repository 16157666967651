import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, take } from 'rxjs';

import { ApiService } from '@emrm/core/services/api';
import { Environments, ENVIRONMENTS } from '@emrm/core/environments';
import { AuthService } from '@emrm/core/auth/service';

import { initialState } from './state';

@Injectable({
	providedIn: 'root',
})
export class AccessDeniedService {
	state$ = new BehaviorSubject(initialState);

	private state = initialState;
	private userId: number | null = null;

	constructor(
		@Inject(ENVIRONMENTS) private readonly environments: Environments,
		private apiService: ApiService,
		private authService: AuthService,
	) {
		this.userId = this.authService.getUserId();
	}

	redirectFromModule(moduleName: string) {
		this.state = {
			...this.state,
			moduleName,
			redirected: true,
		};

		this.state$.next(this.state);
	}

	sendAccessRequest(modules: string[], comment: string) {
		if (this.userId) {
			const body = {
				user_id: this.userId,
				modules,
				comment,
			};

			this.apiService
				.post$(`${this.environments.telegramApiUrl}/access`, body)
				.pipe(take(1))
				.subscribe();
		}
	}
}
